import axios, { baseUrl } from '@/config/axios';
import {
    RequestData,
    RevenueResponse,
    RevenueWowResponse,
    RevenueChartResponse,
    RevenueOrderResponse,
    BestsellingItemsResponse,
    RevenueRefundResponse,
    RevenueCountResponse,
    BrandOrderResponse,
} from '@/interfaces/dashboard/types';
import { IFetchDashboardDataResponse } from '@/components/Dashboard/types';
import { transformError } from '.';

const endpoints = {
    REVENUE: '/vendor/dashboard/revenue/:base_name/:period/:id',
    REVENUE_WoW_REPORT: '/vendor/dashboard/revenue-wow/:base_name/:id',
    REVENUE_ORDER_REPORT: '/vendor/dashboard/order/:base_name/:period/:id',
    REVENUE_CHART_REPORT: '/vendor/dashboard/revenue-chart/:base_name/:id',
    ORDER_REPORT: '/vendor/dashboard/order/dashboard/:id',
    BRAND_ORDER_REPORT: '/vendor/dashboard/order/brand/custom/:id',
    BEST_SELLING_ITEMS: '/vendor/best-selling-item/restaurant/:restaurant_id',
    QLUB_DINER_FEE: '/vendor/dashboard/fee/:base_name/:period/:id',
    REFUND: '/vendor/dashboard/refund/:base_name/:period/:restaurant_id',
    DASHBOARD: '/vendor/dashboard/:base_name/:id',
    DASHBOARD_LEGACY: '/vendor/dashboard/legacy/:base_name/:id',
};

const getQrGroups = (qrGroups: string[] | null | undefined) => {
    return qrGroups ? qrGroups.join(',') : null;
};

class DashboardService {
    public static getInstance() {
        if (!this.instance) {
            this.instance = new DashboardService();
        }

        return this.instance;
    }

    private static instance: DashboardService;

    public async getDashboardData(requestData: RequestData) {
        const { name, id, qrGroups, from, to, isLegacy, ...params } = requestData;
        try {
            const res = await axios.get<{
                message: string;
                data: IFetchDashboardDataResponse;
            }>(
                (isLegacy ? endpoints.DASHBOARD_LEGACY : endpoints.DASHBOARD)
                    .replace(':base_name', name || '')
                    .replace(':id', id || ''),
                {
                    params: {
                        qrGroups: getQrGroups(qrGroups),
                        ...(requestData.period === 'custom' && {
                            startAt: from,
                            endAt: to,
                        }),
                        ...params,
                    },
                    baseURL: isLegacy ? baseUrl : baseUrl.replace('/v1', '/v2'),
                },
            );
            return res.data.data;
        } catch (err) {
            throw transformError(err);
        }
    }

    public async getRevenue(requestData: RequestData) {
        try {
            const res = await axios.get<RevenueResponse>(
                endpoints.REVENUE.replace(':base_name', requestData.name || '')
                    .replace(':period', requestData.period || '')
                    .replace(':id', requestData.id || ''),
                {
                    params: {
                        qrGroups: getQrGroups(requestData.qrGroups),
                        ...(!(requestData.period === 'today' || requestData.period === 'yesterday') && {
                            startAt: requestData.from,
                            endAt: requestData.to,
                        }),
                    },
                },
            );
            return res.data;
        } catch (err) {
            throw transformError(err);
        }
    }

    public async getQlubDinerFee(requestData: RequestData) {
        try {
            const res = await axios.get<any>(
                endpoints.QLUB_DINER_FEE.replace(':base_name', requestData.name || '')
                    .replace(':period', requestData.period || '')
                    .replace(':id', requestData.id || ''),
                {
                    params: {
                        qrGroups: getQrGroups(requestData.qrGroups),
                        ...(!(requestData.period === 'today' || requestData.period === 'yesterday') && {
                            startAt: requestData.from,
                            endAt: requestData.to,
                        }),
                    },
                },
            );
            return res.data;
        } catch (err) {
            throw transformError(err);
        }
    }

    public async getRefund(requestData: RequestData) {
        try {
            const res = await axios.get<RevenueRefundResponse>(
                endpoints.REFUND.replace(':base_name', requestData.name || '')
                    .replace(':period', requestData.period || '')
                    .replace(':restaurant_id', requestData.id || ''),
                {
                    params: {
                        qrGroups: getQrGroups(requestData.qrGroups),
                        ...(!(requestData.period === 'today' || requestData.period === 'yesterday') && {
                            startAt: requestData.from,
                            endAt: requestData.to,
                        }),
                    },
                },
            );
            return res.data;
        } catch (err) {
            throw transformError(err);
        }
    }

    public async getRevenueWowReport(requestData: RequestData) {
        try {
            const res = await axios.get<RevenueWowResponse>(
                endpoints.REVENUE_WoW_REPORT.replace(':base_name', requestData.name || '').replace(
                    ':id',
                    requestData.id || '',
                ),
                {
                    params: {
                        qrGroups: getQrGroups(requestData.qrGroups),
                    },
                },
            );
            return res.data;
        } catch (err) {
            throw transformError(err);
        }
    }

    public async getOrderCount(requestData: RequestData) {
        try {
            const res = await axios.get<RevenueCountResponse>(
                endpoints.ORDER_REPORT.replace(':base_name', requestData.name || '')
                    .replace(':period', requestData.period || '')
                    .replace(':id', requestData.id || ''),
                {
                    params: {
                        qrGroups: getQrGroups(requestData.qrGroups),
                        ...(!(requestData.period === 'today' || requestData.period === 'yesterday') && {
                            startAt: requestData.from,
                            endAt: requestData.to,
                        }),
                    },
                },
            );

            return res.data.data;
        } catch (err) {
            throw transformError(err);
        }
    }

    public async getOrderReportDiffWeekly(requestData: RequestData) {
        try {
            const res = await axios.get<RevenueOrderResponse>(
                endpoints.REVENUE_ORDER_REPORT.replace(':base_name', requestData.name || '')
                    .replace(':period', 'weekly')
                    .replace(':id', requestData.id || ''),
                {
                    params: {
                        qrGroups: getQrGroups(requestData.qrGroups),
                    },
                },
            );
            return res.data;
        } catch (err) {
            throw transformError(err);
        }
    }

    public async getRevenueChartReport(requestData: RequestData) {
        const { period, funnel, from, to } = requestData;
        try {
            const res = await axios.get<RevenueChartResponse>(
                endpoints.REVENUE_CHART_REPORT.replace(':base_name', requestData.name || '').replace(
                    ':id',
                    requestData.id || '',
                ),
                {
                    params: {
                        period: ['today', 'yesterday'].includes(period || '') ? 'last_7_days' : period,
                        funnel,
                        ...(requestData.period === 'custom' && {
                            startAt: from,
                            endAt: to,
                        }),
                    },
                    baseURL: baseUrl.replace('/v1', '/v2'),
                },
            );
            return res.data;
        } catch (err) {
            throw transformError(err);
        }
    }

    public async getBrandOrderReport(requestData: RequestData) {
        try {
            const res = await axios.get<BrandOrderResponse>(
                endpoints.BRAND_ORDER_REPORT.replace(':id', requestData.id || ''),
                {
                    params: {
                        startAt: requestData.from,
                        endAt: requestData.to,
                    },
                },
            );
            return res.data;
        } catch (err) {
            throw transformError(err);
        }
    }

    public async getBestSellingItems(restaurantId: string, period: string) {
        try {
            const res = await axios.get<BestsellingItemsResponse>(
                endpoints.BEST_SELLING_ITEMS.replace(':restaurant_id', restaurantId),
                {
                    params: {
                        period,
                    },
                },
            );
            return res.data;
        } catch (err) {
            throw transformError(err);
        }
    }
}

export default DashboardService;
